@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

@layer base {
  @font-face {
    font-family: "FreeSans";
    src: url(../public/assets/fonts/FreeSans.ttf) format("truetype");
  }
}

@layer base {
  @font-face {
    font-family: "Instrument";
    src: url(../public/assets/fonts/InstrumentSerif-Regular.ttf) format("truetype");
  }
}

@layer base {
  @font-face {
    font-family: "InstrumentItalic";
    src: url(../public/assets/fonts/InstrumentSerif-Italic.ttf) format("truetype");
  }
}

@layer base {
  @font-face {
    font-family: "Symbola";
    src: url(../public/assets/fonts/Symbola.ttf) format("truetype");
  }
}